@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid hsl(0deg 0% 82.19%);

  &>div {
    padding: 24px;

    @media (max-width:768px) {
      padding-bottom: 100px;
    }
  }
}

.signupLink,
.loginLink {
  composes: buttonDefault from global;
  min-width: auto;
  width: auto;
  line-height: 100%;
  min-height: auto;
}

.navLink {
  padding: 36px 0;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--colorBlack);
  text-decoration: none;
  line-height: 100%;

  @media (--viewportLarge) {
    font-size: 18px;
  }

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  &:not(:last-child) {
    margin-right: 20px;

    @media (--viewportLarge) {
      margin-right: 30px;
    }
  }

  &.signupLink {
    padding: 12px 24px;
    color: var(--colorWhite);
  }

  &.loginLink {
    padding: 12px 24px;
    background-color: var(--bgLight);

    &:hover {
      background-color: var(--colorBlack);
      color: var(--colorWhite);
    }
  }
}

.footer {
  max-width: var(--contentMaxWidthPages);

  width: 100%;
  margin: 0 auto;

  & .content {
    display: flex;
    flex-direction: column;

    @media (--viewportMedium) {
      flex-direction: row;
    }

    & .leftSec {
      width: 100%;

      @media (--viewportMedium) {
        width: 40%;
      }
    }

    & .rightSec {
      width: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-top: 30px;
      flex-direction: column;

      @media (--viewportSmall) {
        flex-direction: row;
      }

      @media (--viewportMedium) {
        justify-content: flex-end;
        width: 60%;
        margin-top: 0px;
      }

      & .quickLinks {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;

        @media (--viewportSmall) {
          width: calc(100% / 3);
          margin-bottom: 0px;
        }

        &:last-child {
          margin-bottom: 0px;

          @media (--viewportSmall) {
            width: auto;
          }
        }

        & .footerLink {
          font-size: 16px;
          font-weight: var(--fontWeightRegular);
          display: inline-flex;
          color: var(--colorBlack);
          text-decoration: none;
          line-height: 100%;
          width: 100%;

          @media (--viewportLarge) {
            font-size: 16px;
          }

          &:not(:last-child) {
            margin-bottom: 14px;
          }

          &:hover {
            color: var(--marketplaceColor);
            text-decoration: none;
          }
        }

        & .footerHeading {
          font-size: 19px;
          font-weight: 600;
          display: inline-flex;
          color: var(--marketplaceColor);
          text-decoration: none;
          line-height: 100%;
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }

  & .termsSec {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 24px;
    border-top: 1px solid #eee;
    padding-top: 20px;

    @media (--viewportMedium) {
      justify-content: flex-end;
    }

    @media (--viewportLarge) {
      margin-top: 40px;
    }

    & .footerLink {
      font-size: 16px;
      font-weight: var(--fontWeightRegular);
      display: inline-flex;
      color: var(--colorBlack);
      text-decoration: none;
      line-height: 100%;
      padding: 5px;

      @media (--viewportLarge) {
        font-size: 16px;
      }

      &:last-child {
        padding-right: 0px;
      }

      &:hover {
        color: var(--marketplaceColor);
        text-decoration: none;
      }
    }
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    text-decoration: none;
  }
}

.tagline {
  font-size: 16px;
  font-weight: 300;
}

.logoWrapper {
  max-width: 370px;
}

.logoImage {
  width: 270px;
  min-height: 70px;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (max-width:768px) {
    width: 60%;
    height: auto;
  }

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {

  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {

  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}